import styled from 'styled-components'
import { Button as StyledButton } from '../../../components/Button/Button'
import { colors } from '../../../theme'

export const Header = styled.p`
  font-size: 21px!important;
  color: #1E222D!important;
  font-weight: bold!important;
  line-height: 150%!important;
`

export const SubHeader = styled.p`
  font-size: 21px!important;
  color: ${props => props.isActive ? colors.ebonyClay : colors.inactiveText}!important;
  font-weight: bold!important;
  line-height: 150%!important;
  margin-top: 12px!important;
  margin-bottom: 12px!important;
  cursor: pointer!important;
`

export const Text = styled.p`
  font-size: 13px!important;
  font-weight: normal!important;
  color: #1E222D;
  margin-top: 10px!important;
`

export const StyledLogo = styled.img`
  width: 97px!important;
  height: 24px!important;
`

export const Button = styled(StyledButton)`
`