export const colors = {
  white: '#FFF',
  malibu: '#75ACFF',
  malibuLight: '#6297FF',
  ebonyClay: '#1E222D',
  ebonyClayDark: '#2D3342',
  blueBayoux: '#566280',
  linkWater: '#F2F5FC',
  linkWaterLight: '#D5DEF1',
  linkWaterLighter: '#E5EBF8', // za duzo tych podobnych kolorów
  zumthor: '#EAF3FF',
  dodgerBlue: '#5587FF',
  creamBrulee: '#ffe99f',
  grey: '#1E222D',
  inactiveText: '#98A6C7',
  milkPunch: "#FFF6D8",
  fairPink: "#FFE9EB",
  titanWhite: "#EAEDFF",
};

export const sizes = {
  screenInset: 16,
  onboardingInset: 24,
  listInset: 24,
};

export const fontSizes = {
  main: 13,
  default: 21
};

// Should we switch to rems? ;)
// 1.5rem = 24px
// 1.25rem = 20px
// 0.25 rem = 4px
