import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'
import ReactSlider from 'react-slider'
import Slider from 'rc-slider';
import { AllAnimationsCombined } from '../../../components/Animation/Animation'
import { Button, ButtonAsLink } from '../../../components/Button/Button'
import Images from '../../../images'
import { trackEvent } from '../../../services/helpers'
import '../../../styles/style.scss'
import { StyledLogo } from '../../WebApp/Onboarding/styled'


const FORM_URL = "https://logs.getunderline.io/tracklog"

const style = { width: 400, margin: 50 };

const MarkComponent = ({ value }) => {
  return (
    <div style={{ marginTop: 5 }}>
      <strong>{value}</strong>
    </div>
  )
}

const ExplanationInfo = ({ emoji, label }) => {
  return (
    <div style={{ marginTop: 24 }}>
      <p style={{ fontSize: 32 }}>{emoji}</p>
      <p style={{ fontSize: 15, color: '#1E222D', fontWeight: 600, marginTop: 8 }}>
        {label}
      </p>
    </div>
  )
}

const marks = {
  0: <MarkComponent value={1} />,
  25: <MarkComponent value={2} />,
  50: <MarkComponent value={3} />,
  75: <MarkComponent value={4} />,
  100: {
    label: <MarkComponent value={5} />
  },
}


export const NotAvailable = () => {
  const [value, setValue] = useState(1)
  const i18nHook = useI18next()


  const handleClick = () => {
    fetch(FORM_URL, {
      method: "POST",
      body: JSON.stringify({
        "data": `Uzytecznosc nauki w webie: ${value}`,
        "email": "",
      }),
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json"
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        i18nHook.navigate("/thank-you")
      }
    })
  }

  const handleChange = (value) => {
    setValue(value / 25 + 1)
  }

  const renderStep = () => {
    if (value == 1) {
      return (
        <ExplanationInfo emoji={"🤢"} label={"Zupełnie nieprzydatna"} />
      )
    }

    if (value == 2) {
      return (
        <ExplanationInfo emoji={"😞"} label={"Mało przydatna"} />
      )
    }

    if (value == 3) {
      return (
        <ExplanationInfo emoji={"😐"} label={"Taka sobie"} />
      )
    }

    if (value == 4) {
      return (
        <ExplanationInfo emoji={"🤗"} label={"Trochę przydatna"} />
      )
    }

    if (value == 5) {
      return (
        <ExplanationInfo emoji={"🎉"} label={"Bardzo przydatna"} />
      )
    }
  }

  return (
    <>
      <AllAnimationsCombined singlePage />
      <main>
        <section className="hero is-fullheight">
          <div className="hero-head">
            <div className="columns">
              <div className="column is-justify-content-center is-flex" style={{ marginTop: 80, alignItems: 'center', position: 'relative' }}>
                <div>
                  <StyledLogo
                    src={Images.logoYellowWhite}
                    srcSet={`${Images.logoYellowWhite3x} 3x, ${Images.logoYellowWhite2x} 2x`}
                  />
                </div>

              </div>

            </div>
          </div>
          <div className="hero-body" style={{ alignItems: 'flex-start', marginTop: 230 }}>
            <div className="container">
              <div className="columns is-vcentered" style={{ justifyContent: 'center' }}>
                <div className="column has-text-centered" style={{ maxWidth: 500 }}>
                  <h1
                    className="title is-2 has-text-weight-bold mt-4"
                    style={{ fontSize: 21 }}>
                    Ta funkcja jest jeszcze niedostępna
                  </h1>
                  <p className="mt-5" style={{ fontSize: 13, color: '#566280' }}>
                    Obecnie nadal pracujemy nad rozwojem Underline i zależy nam na Twoim feedbacku, dlatego oceń w skali od 1-5, jak bardzo ta funkcja byłaby dla ciebie przydatna.
                  </p>
                  <div>
                    <div>
                      {renderStep()}
                    </div>
                    <Slider style={{ marginTop: 24 }} min={0} marks={marks} step={null} onChange={handleChange} defaultValue={0} />
                  </div>
                  <div style={{ marginTop: 42 }}>
                    <Button style={{ width: '100%' }} onClick={handleClick}>Wyślij odpowiedź</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
