import { NotAvailable } from '../../containers/WebApp/NotAvailable/NotAvailable';

export default NotAvailable

export const query = graphql`
query NotAvailableQuery($language: String!) {
  locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;