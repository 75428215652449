import { I18nextContext, Link as NavigationLink } from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';

const ButtonStyled = styled.button`
    background-color: ${colors.malibuLight}!important;
    border-color: ${colors.malibuLight}!important;
    color: #FFFFFF!important;
    font-weight: 600!important;
    font-size: 13px!important;
    height: 44px!important;
    padding: 0px 24px!important;
    border-radius: 8px!important;
    &:hover {
        color: #FFF!important;
        border-color: #82B4FF!important;
        background-color: #82B4FF!important;
    }

    &:disabled {
      background-color: #E5EBF8!important;
      border-color: #E5EBF8!important;
      font-weight: 600!important;
      font-size: 13px!important;
      color: #98A6C7!important;
      opacity: 1!important;
    }
`

export const Button = ({ children, onClick, style, className, disabled, type }) => {
  return (
    <ButtonStyled className={`button ${className}`} disabled={disabled} style={style} onClick={onClick} type={type}>{children}</ButtonStyled>
  )
}

export const ButtonAsLink = ({ path, children, style }) => {
  const context = React.useContext(I18nextContext);
  return (
    <NavigationLink
      className="button is-primary has-text-weight-semibold is-3"
      style={style}
      language={context.language}
      to={path}
    >
      {children}
    </NavigationLink>
  )
}